<template>
  <el-dialog
    title="添加角色用户"
    :visible.sync="dialogVisible"
    width="620px"
    @close="dialogClose"
    @open="dialogOpen"
  >
    <el-form
      v-if="dialogVisible"
      ref="form"
      v-loading="loading"
      :model="form"
      label-width="100px"
    >
      <el-form-item
        label="选择用户"
        prop="userIdList"
        :rules="[ { required: true, message: '用户不能为空', trigger: 'change' }]"
      >
        <el-select
          v-model="form.userIdList"
          multiple
          class="mr10"
          placeholder="请选择角色用户"
        >
          <el-option
            v-for="(item,index) in users"
            :key="index"
            :label="item.actualName"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
    </el-form>
    <div
      slot="footer"
      class="dialog-footer"
    >
      <el-button @click="dialogVisible = false">
        取 消
      </el-button>
      <el-button
        type="primary"
        :disabled="loading"
        @click="submitForm"
      >
        提 交
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { success, error } from '@core/utils/utils'
import { AddRoleUser } from '@/api/system/role/role'
import { FilterUserOptions } from '@/api/system/system'

export default {
  data() {
    return {
      loading: false,
      dialogVisible: false,
      users: [],
      form: {
        roleId: '',
        userIdList: [],
      },
    }
  },
  methods: {
    dialogOpen() {
      this.getEnterpriseUersSelect()
    },
    dialogClose() {
      this.form = {
        roleId: '',
        userIdList: [],
      }
      this.$refs.form.resetFields()
    },
    getEnterpriseUersSelect() {
      FilterUserOptions(0, this.form.roleId)
        .then(res => {
          if (res.data.code === 0) {
            this.users = res.data.data
          }
        })
    },
    submitForm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          AddRoleUser(this.form)
            .then(res => {
              this.loading = false
              const resData = res.data
              if (resData.code === 0) {
                success(resData.msg)
                this.dialogVisible = false
                this.$emit('handleFilter')
              } else {
                error(resData.msg)
              }
            })
        }
      })
    },
  },
}
</script>

<style>
    .assest-save-form {
        padding-right: 10px;
    }

    .assest-save-form .el-icon-question {
        position: absolute;
        top: 14px;
        right: -20px;
    }
</style>
